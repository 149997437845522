/* You can add global styles to this file, and also import other style files */

@import "~@angular/cdk/overlay-prebuilt.css";

@import 'ngx-toastr/toastr';

.toast-container .ngx-toastr {
  width: 500px;
}

.toast-message {
  font-size: 14px;
}

body {
  margin: 0;
}


@import 'node_modules/primeng/resources/themes/bootstrap4-light-blue/theme.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeicons/primeicons.css';

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
